import React from 'react';
import { 
  Radio, 
  RadioGroup, 
  FormControlLabel, 
  FormControl, 
  Typography, 
  Box 
} from '@mui/material';
import { AccountCircle, AdminPanelSettings } from '@mui/icons-material';

const IAM_TYPES = {
  ROOT_USER: 'rootUser',
  IAM_USER: 'iamUser'
};

const RadioButtonWithDescription = ({ getIAM, currentValue }) => {
  const handleChange = (event) => {
    getIAM(event.target.value);
  };

  return (
    <FormControl component="fieldset" sx={{ mb: 3, width: '100%' }}>
      <RadioGroup
        aria-label="login-type"
        name="login-type"
        value={currentValue}
        onChange={handleChange}
      >
        <Box 
          sx={{ 
            mb: 2, 
            p: 1.5, 
            border: '1px solid',
            borderColor: currentValue === IAM_TYPES.IAM_USER ? 'primary.main' : 'grey.300',
            borderRadius: 1,
            '&:hover': {
              borderColor: 'primary.main',
              bgcolor: 'action.hover'
            }
          }}
        >
          <FormControlLabel
            value={IAM_TYPES.IAM_USER}
            control={<Radio />}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccountCircle sx={{ mr: 1 }} />
                <Box>
                  <Typography variant="subtitle1" component="div">
                    IAM User
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Sign in with your IAM user account ID or alias
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Box>

        <Box 
          sx={{ 
            p: 1.5, 
            border: '1px solid',
            borderColor: currentValue === IAM_TYPES.ROOT_USER ? 'primary.main' : 'grey.300',
            borderRadius: 1,
            '&:hover': {
              borderColor: 'primary.main',
              bgcolor: 'action.hover'
            }
          }}
        >
          <FormControlLabel
            value={IAM_TYPES.ROOT_USER}
            control={<Radio />}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AdminPanelSettings sx={{ mr: 1 }} />
                <Box>
                  <Typography variant="subtitle1" component="div">
                    Root User
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Sign in with your root user email address
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonWithDescription;