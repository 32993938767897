import React, { useState, useEffect, useContext } from "react";
import { 
  Box, 
  Card, 
  CardContent, 
  CardMedia, 
  TextField, 
  Button, 
  Typography, 
  useMediaQuery, 
  CssBaseline, 
  Grid, 
  Alert
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { AuthFirebaseContext } from './../../createContext/AuthFirebaseContext';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import RadioButtonWithDescription from "./slices/RadioButtonWithDescription";
import RememberCheckbox from "./slices/RememberCheckbox";
import { validateEmail, accountIdValidate, isValidAlias } from "../../utils/helpers";
import dark from '../../themes/dark';
import light from '../../themes/light';

// Environment configuration
const ENV = {
  REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI || 'http://idects.com',
  AUTH_ORIGIN: process.env.REACT_APP_AUTH_ORIGIN || 'accounts',
  TENANT_ROOT_URL: process.env.REACT_APP_TENANT_ROOT_URL || '/tenantrootverifysign',
  TENANT_IAM_URL: process.env.REACT_APP_TENANT_IAM_URL || '/tenantiamverifysign',
};

// Constants
const IAM_TYPES = {
  ROOT_USER: 'rootUser',
  IAM_USER: 'iamUser'
};

const SignIn = () => {
  const isXs = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const { user } = useContext(AuthFirebaseContext);
  
  // Theme state
  const [initialTheme] = useState(localStorage.getItem('userthemes'));
  const themeConfig = initialTheme === "light" ? createTheme(light) : createTheme(dark);
  
  // Form state
  const [formState, setFormState] = useState({
    iamType: IAM_TYPES.IAM_USER,
    credential: '',
    error: ''
  });

  useEffect(() => {
    if (user) {
      navigate("/accessfeature");
    }
  }, [user, navigate]);

  const handleIAMChange = (value) => {
    setFormState(prev => ({
      ...prev,
      iamType: value,
      credential: '',
      error: ''
    }));
  };

  const validateCredential = () => {
    const { iamType, credential } = formState;

    if (!credential) {
      return "Please provide credentials";
    }

    if (iamType === IAM_TYPES.ROOT_USER) {
      return validateEmail(credential) ? "" : "Wrong email format";
    }

    return (accountIdValidate(credential) || isValidAlias(credential))
      ? ""
      : "Wrong Alias/Account ID format";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { iamType, credential } = formState;

    const error = validateCredential();
    if (error) {
      setFormState(prev => ({ ...prev, error }));
      return;
    }

    const baseParams = new URLSearchParams({
      redirect_uri: ENV.REDIRECT_URI,
      origin: ENV.AUTH_ORIGIN,
      iamtype: iamType
    });

    if (iamType === IAM_TYPES.ROOT_USER) {
      navigate(`${ENV.TENANT_ROOT_URL}?${baseParams}&email=${credential}`);
    } else {
      const iamMethod = accountIdValidate(credential) ? 'accountId' : 'alias';
      navigate(`${ENV.TENANT_IAM_URL}?${baseParams}&iam=${iamMethod}&alias=${credential}&email=none`);
    }
  };

  const handleCredentialChange = (event) => {
    setFormState(prev => ({
      ...prev,
      credential: event.target.value,
      error: ''
    }));
  };

  return (
    <ThemeProvider theme={themeConfig}>
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            boxShadow: isXs ? "none" : 6,
            width: isXs ? "100%" : "60%",
            height: isXs ? "100%" : "80%",
            overflowY: isXs ? "auto" : "scroll",
            pt: isXs && 10,
            display: "flex",
            flexDirection: isXs ? "column" : "row",
          }}
        >
          <CssBaseline />
          <Grid container>
            <Grid item sm={6} md={6} xl={6}>
              <CardMedia
                sx={{ 
                  display: isXs ? "none" : "block", 
                  width: "100%", 
                  height: "100%" 
                }}
                image="https://source.unsplash.com/random"
                title="Sign in image"
              />
            </Grid>
            
            <Grid item xs={12} sm={6} md={6} xl={6}>
              <CardContent
                sx={{ 
                  height: "100%", 
                  display: "flex", 
                  flexDirection: "column" 
                }}
              >
                <Typography 
                  variant="h5" 
                  component="h1" 
                  sx={{ textAlign: "left", mb: 2 }}
                >
                  Sign in
                </Typography>

                <form onSubmit={handleSubmit}>
                  <RadioButtonWithDescription 
                    getIAM={handleIAMChange} 
                    currentValue={formState.iamType}
                  />
                  
                  <TextField
                    error={!!formState.error}
                    required
                    fullWidth
                    id={formState.iamType === IAM_TYPES.IAM_USER ? "alias" : "email"}
                    label={formState.iamType === IAM_TYPES.IAM_USER ? "Alias/Account ID" : "Email Address"}
                    name={formState.iamType === IAM_TYPES.IAM_USER ? "alias" : "email"}
                    type={formState.iamType === IAM_TYPES.IAM_USER ? "text" : "email"}
                    value={formState.credential}
                    onChange={handleCredentialChange}
                    autoComplete={formState.iamType === IAM_TYPES.IAM_USER ? "Alias/Account ID" : "Email Address"}
                    sx={{ mb: 2 }}
                    helperText={formState.error}
                  />

                  <Button 
                    type="submit" 
                    variant="contained" 
                    fullWidth
                    sx={{ 
                      mb: 2, 
                      whiteSpace: "nowrap", 
                      textOverflow: "ellipsis",
                      overflow: "hidden"
                    }}
                  >
                    Continue With Sign In
                  </Button>
                </form>

                <RememberCheckbox />
                
                <Typography variant="body1" sx={{ fontSize: "9px" }}>
                  By continuing, you are agreeing with terms, conditions and policies of service. 
                  We use Cookies for enhanced experience.
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </ThemeProvider>
  );
};

export default SignIn;