import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeProvider, useTheme,createTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { CSSTransition, TransitionGroup  } from "react-transition-group";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import { uiActionCreators } from './../../store/actions';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import NavContent from './NavContent';
import { AuthFirebaseContext } from './../../createContext/AuthFirebaseContext';
import LangBtn from './LangBtn';
import { useCurrentPath } from './../../Components/ToolKits/useCurrentPath';
import {getCookie , setCookie, unsetCookie} from "./../../utils/cookie";
import ThemeSwitchNew from "../../Components/ToolKits/ThemeSwitchNew";
import axios from 'axios';
import dark from '../../themes/dark';
import light from '../../themes/light';
import  WhiteLogo  from '../../static/images/logo/idts-small-white.png';
import  BlackLogo  from '../../static/images/logo/idts-small-black.png';
import BackgroundLetterAvatars from "../../Components/ToolKits/BackgroundLetterAvatars";
import { auth,app,fireStore, } from "../../firebase/Firebase";
import { doc, getDoc } from "firebase/firestore";

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function ElevationScroll(props) {
  const { children, window, threshold } = props;

  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const MyButton = styled(Button)({
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  border: 0,
  borderRadius: 3,
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  color: "white",
  height: 48,
  padding: "0 30px",
});

const SlidingSearch = styled(Paper)`
  border-radius: 0px;
  height: ${(props) => (props.isvisible ? '64px' : '0')};
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: height 0.5s ease-in-out;
  overflow: hidden;
  z-index: 1;
`;

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: '100px',
  marginTop: '12px',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '90%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100ch',
    },
  },
}));

const dataStack = [
  {
    image: '/login',
    title: 'CRM',
    description: 'Convert leads and close sales deals faster',
    arrow: 'RIGHT ARROW',
  },
  {
    image: 'https://www.zoho.com/crm/?ireft=nhome&src=fa',
    title: 'Mail',
    description: 'Secure email service for businesses',
    arrow: 'LEFT ARROW',
  },
  {
    image: 'https://www.zoho.com/crm/?ireft=nhome&src=fa',
    title: 'Books',
    description: 'Powerful financial platform for your growing business',
    arrow: 'LEFT ARROW',
  },
  {
    image: 'https://www.zoho.com/crm/?ireft=nhome&src=fa',
    title: 'Desk',
    description: 'Helpdesk software to deliver great customer support',
    arrow: 'LEFT ARROW',
  },
  {
    image: 'https://www.zoho.com/crm/?ireft=nhome&src=fa',
    title: 'Campaigns',
    description: 'Create, send and track targeted email campaigns that drive sales',
    arrow: 'LEFT ARROW',
  },
];



export default function Featurette(props) {
  const [initialTheme, setInitialTheme] = React.useState(localStorage.getItem('userthemes'));
  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const dispatch = useDispatch();
  const { getTheme, setTheme } = bindActionCreators(uiActionCreators, dispatch);
  const [scrolling, setScrolling] = useState(false);
  const [threshold, setThreshold] = useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [anchorElProfileMenu, setAnchorElProfileMenu] = React.useState(null);
  const openPm = Boolean(anchorElProfileMenu);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const uiState = useSelector((state) => state.ui);
  //console.log(uiState);
  const [themeMode, setThemeMode ]  = useState(uiState.theme);
  const uiThemeMode = uiState.theme;
  const [isContained, setIsContained] = useState(false);
  const [isXsScreen, setIsXsScreen] = useState(false);
  const [lang, setLang] = useState('EN');
  //const [userInfo, SetUserInfo] = React.useState([]);

  const menuItems = ['Apps', 'Suites', 'Zoho', 'Marketplace', 'Mobile'];
 
  const isXsScreens = useMediaQuery('(max-width:600px)');

  const { user,logOut,verifyIfUserIsEnrolled, verifyUserEmail, userInfo } = useContext(AuthFirebaseContext);
  const navigate = useNavigate();


  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const makeLang = (lang) => {
    setLang(lang);
    //console.log(data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  }
   const handleLogout = async () => {

      try {
      await logOut();
      //await account.deleteSession('current');
      //const path = useCurrentPath();
      navigate('/');
      //alert('good');
    } catch (error) {
      // eslint-disable-next-line no-console
      //do some effect here
      console.log(error);
     // alert('problem');
    }
    
  };

  const currentUser = user;
  async function sendEmail() {
    if (currentUser) {
      const response = await verifyUserEmail(currentUser);

      if (response) {
          alert('An Email has been sent to you');
      }else {
          alert('Something went wrong');
      }
    }
  }

  const toggleThemeColorMode = (mode) => {

    setInitialTheme(mode);
    localStorage.setItem('userthemes', mode  );
    console.log(mode);
   
  };

  /*
  useEffect(()=>{
    const docRef = doc(fireStore, "users", user.uid);
    getDoc(docRef).then((docSnap)=>{
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        SetUserInfo(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }).catch((error)=>{
      // navigate  to another place since internet is down and page relies on internet connectivity
      console.log(error);
    });
    
  },[]);
*/
  useEffect(()=>{
    auth.currentUser.getIdToken(true).then((idToken) => {
      // Parse the JSON Web Token (JWT)
      const payload = JSON.parse(atob(idToken.split('.')[1]));
      
      // Check for the custom claim
      if (payload['rbac']) { 
        console.log('Custom claim exists:', payload['rbac']);
        // Proceed to allow access
      } else {
        console.log('No custom claim found');
        // Redirect or restrict access
      }
    }).catch((error) => {
      console.error('Error fetching ID token:', error);
    });
  },[])


  const iconStyles = {
    transition: 'color 0.3s ease-in-out',
  };
   // Function to handle scroll event
   const handleScroll = () => {
    if (window.scrollY > 1 ) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    // Add a scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // The empty dependency array ensures the effect runs only once

  const leftDivStyle = {
    width: "390px",
    backgroundColor:" #fff",
    border: "1px solid #e7ebf0",
    borderRadius: "5px",
    marginBottom: "28px",
    position: "relative",
    zIndex: "3",
    float:"right"
  };

  const handleShowNav = () => {
    //setScrolling(true);
    setThreshold(10000);
    setShowNav(true);
    document.body.style.overflow = 'hidden'; // Disable body scrolling
  };

  const handleHideNav = () => {
    setThreshold(0);
    //setScrolling(false);
    setShowNav(false);
    document.body.style.overflow = 'auto'; // Enable body scrolling

  };

  const handleClickProfileMenu = (event) => {
    setAnchorElProfileMenu(event.currentTarget);
  };
  const handleCloseProfileMenu = () => {
    setAnchorElProfileMenu(null);
  };

  const handleLogoutOption = () => {
     //logOut();
    setAnchorElProfileMenu(null);
    const cookieToken = getCookie('_id-tnt_GT4RTY5639UNCGDTSA');
    const tenantId = getCookie('_txsuid');

    if (tenantId === "" || cookieToken === " ") {
      logOut();
    }else{
      axios
        .post('https://us-central1-benhass-65ea4.cloudfunctions.net/idectsaccountserver/exituser', {cToken: cookieToken, tenantId: tenantId})
          .then((response) => {
            console.log(response);
            //unsetCookie('_id_4RTY5639UNCGDTSA', ".idects.com","None");
            document.cookie = "_txsuid=; max-age=-1; domain=.idects.com; path=/; SameSite=None; Secure";
            document.cookie = "_id-tnt_GT4RTY5639UNCGDTSA=; max-age=-1; domain=.idects.com; path=/; httpOnly, SameSite=None; Secure";

            //unsetCookie('_id-tnt_GT4RTY5639UNCGDTSA', ".idects.com","None");
            //unsetCookie('_txsuid', ".idects.com","None");
            // unsetCookie('tntStp', ".idects.com","None");
            document.cookie = "tntStp=; max-age=-1; domain=.idects.com; path=/; SameSite=None; Secure";
            logOut();
            // document.cookie = `_lid_4RTY5639UNCGDTSLH=${response.data.cookie}; max-age=3600;  path=/; Secure`;
            // console.log('byeee');
            //window.location.replace("http://idects.com");
            // use navigate intead or wait 5 seconds before rdirect
            //console.log("yah it safe to send token to this palce");
          })
          .catch((error) => {
            logOut();
            //unsetCookie('_id-tnt_GT4RTY5639UNCGDTSA', ".idects.com","None");
            //unsetCookie('tidtoken', ".idects.com","None");
            //unsetCookie('tntStp', ".idects.com","None");
            //document.cookie = "_txsuid=; max-age=-1; domain=.idects.com; path=/; SameSite=None; Secure";
            //document.cookie = "_id-tnt_GT4RTY5639UNCGDTSA=; max-age=-1; domain=.idects.com; path=/; httpOnly, SameSite=None; Secure";
            // document.cookie = "tntStp=; max-age=-1; domain=.idects.com; path=/; SameSite=None; Secure";

            console.log(error);
            console.log("error in logging out");
            alert("logout by force");
          });
        }
    // callcloud first
  };

    

  const showSearch = () => {
    setIsSearchVisible(true);
  };

  const hideSearch = () => {
    setIsSearchVisible(false);
  };


  const themetest = initialTheme === "light" ? createTheme(light) : createTheme(dark) ;
  const theme = useTheme();
  
  return (
    <React.Fragment>
      <ThemeProvider theme={themetest}>
        <CssBaseline />
        <Box sx={{ position: 'relative',  overflow: 'hidden', backgroundImage: initialTheme === "light" ? "linear-gradient(rgb(206, 229, 253), rgb(255, 255, 255))":"linear-gradient(rgb(2, 41, 79), rgba(9, 14, 16, 0))",    backgroundSize: "100% 20%",
    backgroundRepeat: "no-repeat" }}>
                
          <ElevationScroll {...props} threshold={threshold}>
            <AppBar sx={{ backgroundColor: scrolling ? 
                          initialTheme === 'light' ? 
                            threshold === 0 ? 'rgba(255,255,255,0.9)':"#fff" :'' 
                            : 'transparent' }}>
              <Toolbar>
                
                <Box sx={{mr: 2 }}>
                  <img src={initialTheme === "dark" ? WhiteLogo : BlackLogo } height="20px"/>
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Button  onClick={handleShowNav} sx={{ color: initialTheme === 'light' ? 'rgba(0, 0, 0, 0.87)':'#fff' }}>
                    Products
                  </Button>
                  <Button component={Link} to="/configs" sx={{ color: initialTheme === 'light' ? 'rgba(0, 0, 0, 0.87)':'#fff' }}>
                    ACCOUNTS SETTINGS 
                  </Button>
                  
                  <Button component={Link} to="/tenantconfig" sx={{ color: initialTheme === 'light' ? 'rgba(0, 0, 0, 0.87)':'#fff' }}>
                    TENANCY CONFIGS
                  </Button>
                  <Button  sx={{ color: initialTheme === 'light' ? 'rgba(0, 0, 0, 0.87)':'#fff' }}>
                    POLICIES
                  </Button>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <IconButton size="large"  sx={{ color: initialTheme === 'light' ? 'rgba(0, 0, 0, 0.87)':'', marginRight:'5px' }} onClick={showSearch}>
                  <SearchIcon />
                </IconButton>
                <Typography sx={{ color: initialTheme === 'light' ? 'rgba(0, 0, 0, 0.87)':'#fff', userSelect:'none', margin:'auto 0px'}}>{lang}</Typography>
                <LangBtn theme={initialTheme} setMainNav={makeLang}/>
                <ThemeSwitchNew triggerTheme={toggleThemeColorMode} modeState ={initialTheme}/>
                  
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    sx={{ color: themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)':'' }}
                  >
                    <Badge badgeContent={17} color="error">
                      <NotificationsIcon sx={{ color: themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)':'' }}/>
                    </Badge>
                  </IconButton>
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClickProfileMenu}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={openPm ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openPm ? 'true' : undefined}
                    >
                      
                      {userInfo !== null && userInfo.avatarStyle === "initials" ? 
                        <BackgroundLetterAvatars displayName={user.displayName} /> :
                        <Avatar alt={user.email} src={ user.photoURL  } />
                      }
                    </IconButton>
                </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorElProfileMenu}
                  id="account-menu"
                  open={openPm}
                  onClose={handleCloseProfileMenu}
                  onClick={handleCloseProfileMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                    <MenuItem onClick={handleCloseProfileMenu}>
                      <Avatar /> Profile
                    </MenuItem>
                    <MenuItem onClick={handleCloseProfileMenu}>
                      <Avatar /> My account
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleCloseProfileMenu}>
                      <ListItemIcon>
                        <PersonAdd fontSize="small" />
                      </ListItemIcon>
                      Access your Apps
                    </MenuItem>
                    <MenuItem onClick={handleCloseProfileMenu}>
                      <ListItemIcon>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      Settings
                    </MenuItem>
                    <MenuItem onClick={handleLogoutOption}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                  <SlidingSearch >
                    <Grid container spacing={2}>
                      
                      <Grid item xs={10}>
                        <Search>
                          <SearchIconWrapper>
                            <SearchIcon />
                          </SearchIconWrapper>
                          <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                          />
                        </Search>
                      </Grid>
                      <Grid item xs={2}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center" sx={{ paddingTop:'10px'}}>
                          
                          <IconButton size="large"  sx={{ color: themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)':'', marginRight:'5px' }} onClick={hideSearch}>
                            <CloseRoundedIcon />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid>
                    
                    
                  </SlidingSearch>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </Box>
              </Toolbar>

            </AppBar>
          </ElevationScroll>

          <Toolbar id="back-to-top-anchor" />
        
          <Box sx={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", pt: "100px"}}>
            <Typography variant='h2' sx={{mb : 1, fontFamily:"roboto", fontWeight:600 }}>Innovation with Excellence</Typography>
            <Typography variant='body2'>Explore our cutting-edge dashboard,delivering high-quality solutions tailored to your needs. </Typography>
            <Typography variant='body2'>Elevate your experience with top-tier features and services.</Typography>

            <Box>
              <Button  variant='contained' sx={{mt:2}} component={Link} to="/tenantonboarding">
                RAEDY? GET ONBOARDED
              </Button>
            </Box>
          </Box>
          
          <Box sx={{mt:10}}>
            will continue designing this page later. the purpose of this page is to show users a showcase of actviies and 
            projects .. like and intriductory page they hit after logging

            notes by ohene 
          </Box>

      

          <nav id="myNav" style={{
            opacity: showNav ? 1 : 0,
            display: showNav ? 'block' : 'none',
            visibility: showNav ? 'visible' : 'hidden',
            pointerEvents: showNav ? 'auto' : 'none',
            zIndex: showNav ? 1000 : 0,
            position:'fixed',
            width:'100%',
            top:'64px',
            left:'0',
            height:'100vh',
            overflow:'hidden',
            transform: 'translate(0%, 0%)',
            backgroundColor: theme.palette.background.paper,
            borderTop:'1px solid #e7ebf0',
            transition: 'opacity .4s ease, visibility .4s ease'
            }}>
            <Box sx={{ height: '54px', width: '100%', backgroundColor:'#f8f9fb'}}>
              <Box className={'pdtNavHolder'} sx={{ paddingLeft: '30px', paddingRight: '30px', width: '100%', overflow:'hidden'}}>
                <Box component="span"> 
                  <List sx={{ display: 'inline-flex', padding: 0 }}>
                  {menuItems.map((item, index) => (
                    <ListItem
                        key={index}
                        button
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index)}
                        sx={{
                          display: 'inline-block',
                          padding: '15px 0',
                          fontSize: '15px',
                          color: 'rgba(0, 0, 0, 0.54)',
                          marginRight: '50px',
                          borderBottom: '2px solid transparent',
                          cursor: 'pointer',
                          '&:hover': {
                            color: '#056cb8',
                            background:'none',
                            borderBottom: '1px solid #f60014',
                            opacity: 1,
                          },
                          ...(selectedIndex === index && {
                            color: '#056cb8',
                            background:'none',
                            borderBottom: '2px solid #f60014',
                            opacity: 1,
                          }),
                        }}
                      >
                        {item}
                      </ListItem>
                  ))}
                  </List>
                </Box >
                <Box component="span"><Link to="/" sx={{cursor:'pointer', textDecoration:"none", userSelect:'none'}}>Explore all Products</Link></Box>
                <Box component="span" sx={{ float:'right'}}>
                  <IconButton aria-label="close" onClick={handleHideNav}  size="large" sx={{ color: 'rgba(0, 0, 0, 0.54)', textAlign:'right', marginTop:'2px' }} >
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>

            <Box className={'contentHolder'}>
              {selectedIndex !== null && (
                <NavContent content={menuItems[selectedIndex]} />
              )}
            </Box>

          </nav>
          <ScrollTop {...props}>
            <Fab size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>
          <Box component='footer' sx={{ backgroundColor: '#0b0d1f', width: '100%',display: 'block',mt:"200px",  padding: '40px 0 40px', color: '#fff', textAlign: 'center',position: 'relative' }}>
            HJGHJGHVGNBVBFGHVHJVJNHVBVGHG VHGH JHGJHGJ BJHGJ BJHK JKHGJ JHJK JKG4675789889N  568 HGHGHG
          </Box>
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
}
