import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { positions } from '@mui/system';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import Grid from '@mui/material/Grid';
import { deepOrange, deepPurple } from '@mui/material/colors';
// import { useUserAuth } from '../createContext/UserAuthContext';
function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const NoMatch = () => {
  const [rotate, setRotate] = React.useState(false);
  const navigate = useNavigate();
  /*
  const { logOut, user } = useUserAuth();
    const navigate = useNavigate();
    const handleLogout = async () => {
      try {
        await logOut();
        navigate('/');
      } catch (error) {
        console.log(error.message);
      }
    };

  */
  const returnhome = () => {
    navigate('/');
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <Box>
        <Typography variant="h1">OPPS SORRY </Typography>
      </Box>
      <Button onClick={returnhome} autoFocus>
        Back Home
      </Button>
    </>
  );
};

export default NoMatch;
