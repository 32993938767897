import * as React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SnackbarContent from '@mui/material/SnackbarContent';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function CircularWithValueLabel() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress}  color="secondary"   />;
}

export default function Offline( props ) {

  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;
  
  // Define a function to handle the snackbar close event
  const handleClose = () => {
    // setOpen(false);
  };

  const OfflineData = () =>{
    return (
      <Box 
        sx={{
          display:"flex",
          flexDirection: "row",
          justifyContent:"center",
          alignItems:"center"
        }}>
          <Box>You are offline You are offline You are offline You are offline</Box>
          <Box> <CircularWithValueLabel/></Box>
        
        </Box>
      );
  }
  
  const action = (
    <CircularWithValueLabel/>
  );


  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.offline}
        onClick={handleClose}
      >
        <Snackbar
          open={props.offline }
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleClose}
          TransitionComponent={SlideTransition}
          // Display different messages based on the network status
        
          key={SlideTransition.name}
          sx={{ mt : 10, }}
        >
          <Stack spacing={2} sx={{ maxWidth: 600 }}  direction="row">
            <SnackbarContent
              message="You are offline. reconnecting ..."
              action={action}
            />
          </Stack>
        </Snackbar>
      </Backdrop>
    </div>
  );
}
