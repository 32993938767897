import { combineReducers } from 'redux';
import baseReducer from './baseReducer';
import uiReducer from './uiReducer';
import connectReducer from './connectReducer';

const rootReducer = combineReducers({
  base: baseReducer,
  ui: uiReducer,
  connect: connectReducer,
});

export default rootReducer;
