export function validateEmail(email) {
    // This regular expression matches the general format of an email address
    // It allows alphanumeric characters, dots, dashes and underscores before the @ symbol
    // It allows alphanumeric characters, dots and dashes after the @ symbol
    // It requires at least one dot after the @ symbol, followed by at least two alphanumeric characters
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    // The test method returns true if the email matches the regex, false otherwise
    return regex.test(String(email).toLowerCase());
  }

  // Define a function that takes a accountId as a parameter
export function accountIdValidate(accountId) {
  const pattern = /^[0-9]{12}$/;
  return pattern.test(accountId);
}

// Define a function that takes a variable as a parameter
export function isValidAlias(inputString) {
  const pattern = /^(?![0-9])[a-z0-9]{4,20}|^[-._]+$/;
  
  return pattern.test(inputString);
}
