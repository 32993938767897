import React, { useState, useContext } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { AuthFirebaseContext } from "./../../createContext/AuthFirebaseContext";

const PrivateRoute = ({ children }) => {
  const { user } = useContext(AuthFirebaseContext);

  if (user && !user.emailVerified) {
    return children ? children : <Outlet />;
  }  else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
