import React, { useState, useContext } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import { AuthFirebaseContext } from './../../createContext/AuthFirebaseContext';
import { Box, Card, CardContent, CardMedia, TextField, Button, Typography, useMediaQuery, CssBaseline, Grid, Alert, Stack, Divider  } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import SignUpCarousel from './../../Components/ToolKits/SignUpCarousel';
//import { createUserWithEmailAndPassword, GoogleAuthProvider,signInWithPopup,sendEmailVerification } from "firebase/auth";
//import { auth,app,fireStore, } from "../../firebase/Firebase";
//import { doc, setDoc , getFirestore } from "firebase/firestore";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" to="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function errorhandler(error){
  
  switch (error) {
    case "auth/email-already-in-use":
      // Display an error message to the user indicating that the email address is already in use.
      break;
    case "auth/invalid-email":
      // Display an error message to the user indicating that the email address is invalid.
      break;
    case "auth/weak-password":
      // Display an error message to the user indicating that the password is too weak.
      break;
    case "auth/operation-not-allowed":
      // Display an error message to the user indicating that the operation is not allowed.
      break;
    case "auth/too-many-requests":
      // Display an error message to the user indicating that they have made too many requests.
      break;
    default:
      // Display an error message to the user indicating that an unknown error occurred.
      break;
  }
}

function getErrorRule(inputString) {
  // Use a regular expression to match text inside parentheses
  const regex = /\(([^)]+)\)/;
  
  // Use the match() method to find the first match
  const match = inputString.match(regex);
  
  // If a match is found, return the captured group (text inside parentheses)
  if (match && match[1]) {
    return match[1];
  } else {
    // Return null or a default value if no match is found
    return null;
  }
}


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp() {
  //const { signUp } = useUserAuth();
  const [email, setEmail] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [address, setAddress] = useState('');
  const [errors, setErrors] = useState({ fname: "", lname: "", email:"", password:""});
  const [password, setPassword] = useState('');
  const [sigupTune, setSignupTune] = useState(false);
  //const { googleSignIn, signUp  } = useUserAuth();
  const [error, setError] = useState('');
  const [firebaseErr, setFirebaseErr] = useState('');
    const theme = useTheme();
  const isXs = useMediaQuery('(max-width:600px)');


   const { user, googleSignIn, signUp } = useContext(AuthFirebaseContext);

  //const db = getFirestore(app);
//console.log(UseUserAuth());

  const navigate = useNavigate();
   

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/");
    } catch (error) {
      console.log(error.message);
      //console.log('google log in success')
    }
  };


function validateFormData(fname, lname, email, password) {
  const err = [];

  // Validate first name.
  if (fname === "" || !fname.match(/^[a-zA-Z]+$/)) {
    err.push("First name must be non-empty and contain only letters.");
  }

  // Validate last name.
  if (lname === "" || !lname.match(/^[a-zA-Z]+$/)) {
    err.push("Last name must be non-empty and contain only letters.");
  }

  // Validate email address.
  if (email === "" || !email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
    err.push("Invalid email address.");
  }

  // Validate password.
  if (password === "" || password.length < 8 || !password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)) {
    err.push("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one symbol. eg. Password123!");
  }

  setErrors([...errors]);
  return err;
}


function validatePassword(password) {
  // Define the regex pattern for the password
  var pattern = /^ (?=.* [A-Z]) (?=.* [!@#$%^&*]) .{8,}$/;
  // Test the password against the pattern
  return pattern.test(password);
}


  const handleSubmit = async(event) => {
    event.preventDefault();
    var err = errors;

    setSignupTune(true);

    if (fname === "" || !fname.match(/^[a-zA-Z]+$/)) {
      err.fname = "Invalid name format";
    }else{
       err.fname = "";
    }

    if (lname === "" || !lname.match(/^[a-zA-Z]+$/)) {
      err.lname = "Invalid name format";
    }else{
       err.lname = "";
    }

    // Validate email address.
    if (email === "" || !email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      err.email = "Invalid email address.";
    }else{
      err.email = "";
    }

    //validate password
    if(password ===  "" ){
      err.password = "Invalid password format.";
    }else{
      err.password = "";
    }


    setErrors(err);

    if(err.lname === "" && err.fname === "" && err.email === "" && err.password === "" ){
      try {
        await signUp(email, password, address, fname, lname);
          //console.log('Success')
          navigate("/uncomfirmedverfitcationotp");
        } catch (err) {
          
          setFirebaseErr(err.message);
          console.log(err.message);
        }
        
      }else{
         setSignupTune(false);
      }
    
    console.log(err);
    
  };



  const handleSubmitFb = async () => {
    //e.preventDefault();
    //setError("");
    

    try {
      await signUp(email, password, address, fname, lname);
      //console.log('Success')
      navigate("/uncomfirmedverfitcationotp");
    } catch (err) {
      
      setErrors([...errors, err.message]);
      console.log(err.message);
    }
  }
/*
  
  appwrite use
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors('');

    const fullname = `${fname} ${lname}`;
    const promise = signUp(email, password, fullname);
    promise.then(
      (response) => {
        console.log(response);
        navigate('/');
      },
      (error) => {
        console.log(error);
        setErrors(error);
      }
    );
  }

  */

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", height:"100dvh", display: "flex" , flexDirection: "row", justifyContent:"center", alignItems: "center"}}>
        <Card
          // child element with box shadow and 70% width and height
          sx={{
            boxShadow: isXs ? "none" : 6,
            width: isXs ? "100%" : "80%",
            height: isXs ? "100%" : "80%",
            display: "flex",
            flexDirection: isXs ? "column" : "row",
          }}
        >
          <CssBaseline />
          <Grid container>
            <Grid  item  sm={7} md={7} xl={7} sx={{ borderRight: "1px solid #fff"}}>
              { firebaseErr !== "" && firebaseErr }
              <Box sx={{ display:"flex" , flexDirection:"column", justifyContent:"space-between", alignItems:"flex-start",height:"100%", p:5}}>
                <Typography variant="body2" >IDT logo</Typography>
                <Box>
                  <Typography variant="h3" component="h3" sx={{mb:2}}>Don't have an account?</Typography>
                  <Typography variant="body2" component="p">Sign up to access all the feature we have to offer. </Typography>
                  <Typography variant="body2" component="p">Manage all your business needs and communication from one place. Lets help streamline your activities for efficiency </Typography>
                  <Box
                    sx={{direction:"row",
                    justifyContent:"flex-start",
                    alignItems:"flex-start",
                    mt:1
                  }}
                  >
                   <FacebookIcon sx={{  pr:1}}/> 
                   <GoogleIcon sx={{  pr:1}}/> 
                   <LinkedInIcon sx={{  pr:1}}/> 
                   <TwitterIcon sx={{  pr:1}}/> 
                  </Box>
                </Box>
                
              </Box>

            </Grid>

             <Grid  item xs={12} sm={5} md={5} xl={5}>

              <Box sx={{pt:5, pr:5, pl:5}}>
                <Typography component="h5" variant="h5">
                  Sign up
                </Typography>
               
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={errors.fname !== "" ? true : false}
                        variant="standard"
                        autoComplete="given-name"
                        name="firstName"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        autoFocus
                        onChange={(e) => setFname(e.target.value)}
                        helperText={errors.fname === "" ? "eg. John": errors.fname }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={errors.lname !== "" ? true : false}
                        variant="standard"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="family-name"
                        onChange={(e) => setLname(e.target.value)}
                        helperText={errors.lname === "" ? "eg. Smith": errors.lname }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={errors.email !== "" ? true : false}
                        required
                        fullWidth
                        variant="standard"
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={(e) => setEmail(e.target.value)}
                        helperText={errors.email === "" ? "eg. bobgray@gmail.com": errors.email }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={errors.password !== "" ? true : false}
                        required
                        variant="standard"
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                        helperText={errors.password === "" ? "min. 8 characters -eg. Hello@123": errors.password }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox value="consented" color="primary" />}
                        label="Consent to terms and conditions."
                      />
                    </Grid>
                  </Grid>
                  
                  <Grid container>
                   <Grid item xs={6}>
                     <Button
                        type="submit"
                        disabled= {sigupTune ? true :false }
                        fullWidth
                        variant="contained"
                        
                        sx={{mt: 3, mb: 2 , textOverflow:"ellipsis", whiteSpace: "nowrap"}}
                      >
                        {sigupTune ? 'Creating' :' SIGN UP'}
                      </Button>
                   </Grid>
                   <Grid item xs={6} sx={{  pl :1}}>
                      <Link to="/" >
                        Already have an account? Sign in
                      </Link>
                    </Grid>
                    
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </React.Fragment>
  );
}

