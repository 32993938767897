export const getTheme = (theme) => {
  return (dispatch) => ({
    type: 'SET_THEME',
    payload: theme,
  });
};

export const setTheme = (theme) => {
  return (dispatch) => {
    dispatch({
      type: 'GET_THEME',
      payload: theme,
    });
  };
};

export const getSelected = (index) => {
  return (dispatch) => ({
    type: 'SET_SELECTED',
    payload: index,
  });
};

export const setSelected = (index) => {
  return (dispatch) => {
    dispatch({
      type: 'GET_SELECTED',
      payload: index,
    });
  };
};
