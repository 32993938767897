import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function RememberCheckbox() {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <FormControlLabel 
    control={ <Checkbox checked={checked}
    onChange={handleChange}
    inputProps={{ 'aria-label': 'controlled' }} />} label="Remember this Setting" />
   
  );
}