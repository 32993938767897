import react, {useState, Fragment } from "react";
import {
    getMultiFactorResolver,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    signInWithPopup,
    GoogleAuthProvider,
  } from "firebase/auth";
  import {
    Button, 
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { handleFirebaseAuthLoginError } from "../../../utils/authFunctions";
import { auth } from "../../../firebase/Firebase";
import axios from 'axios';
import MfaValidation from "./MfaValidation";
import { useSearchParams, useNavigate } from 'react-router-dom';

const GoogleSignIn = (props) =>{

    const {signInConfig, recaptchaToken } = props;
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

      // mfa
      const [resolve, setResolve] = useState(null);
      const [recaptcha, setRecaptcha] = useState(recaptchaToken);
      const [hints, setHints] = useState();
      const [showSignInMfa, setShowSignInMfa] = useState(false);
      const [verificationId, setVerificationId] = useState('');

 
      

    const handleGoogleSignIn = async(e) =>{
        e.preventDefault();
        auth.tenantId = signInConfig.tenantId;
        auth.languageCode = 'en';

        const googleAuthProvider = new GoogleAuthProvider();
        signInWithPopup(auth,googleAuthProvider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // IdP data available using getAdditionalUserInfo(result)
                const idToken = result.user.getIdToken();
                let tenantId  = signInConfig.tenantId;
                const userData = {idToken,tenantId };

                axios
                .post('https://us-central1-benhass-65ea4.cloudfunctions.net/idectsaccountserver/tenantassigncookie', userData)
                    .then((response) => {
                    //console.log(response);
                    // Get the JWT from the response
                    const token = response.data.tId;
                    document.cookie = `_id-tnt_GT4RTY5639UNCGDTSA=${response.data.cookie}; max-age=432000000; domain=.idects.com; path=/; httpOnly, SameSite=None; Secure`;
                    // document.cookie = `_lid_4RTY5639UNCGDTSLH=${response.data.cookie}; max-age=3600;  path=/; Secure`;
                    // Store the JWT in a cookie with httpOnly and secure flags
                    document.cookie = `tidtoken=${token}; max-age=432000000; domain=.idects.com; path=/; SameSite=None;`;
                    document.cookie = `tntStp=true; max-age=432000000; domain=.idects.com; path=/; SameSite=None; Secure`;

                    console.log('cookie made for both');
                    
                    })
                    .catch((error) => {
                    console.log(error);
                    console.log("error in getting data");
                    });
            

                const refererRoute = searchParams.get('redirect');
        
                if (refererRoute !== '') {
                    switch (refererRoute) {
                        case "idectsindex":
                        window.location.replace(`http://idects.com/hometab?auth=true&referer=accounts&occ=tnt`);
                        break;
                    
                        default:
                        window.location.replace(`http://idects.com/hometab`);
                        break;
                    }
                }else{
                    // means the person come to accounts directly
                    navigate(`/accessfeature`);
                }
                // ...
            }).catch((error) => {
                
                //console.log(error.message);
                if (error.message == 'auth/multi-factor-auth-required') {
                    // The user is a multi-factor user. Second factor challenge is required.
                    const resolver = getMultiFactorResolver(auth, error);
                    setResolve(resolver);
                    // console.log(resolver);
                    setHints(resolver.hints[0]);
                    
                    setShowSignInMfa(true);
                    const phoneInfoOptions = {
                        multiFactorHint: resolver.hints[0],
                        session: resolver.session
                    };
            
                    const phoneAuthProvider = new PhoneAuthProvider(auth); 
            
                    phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptcha)
                    .then(function (verificationId) {
                        // verificationId will be needed for sign-in completion.
                        setVerificationId(verificationId);
                        setRecaptcha(null);
                    });
                }else{
                    
                    alert(error.message);
                }
            });
    }



    return(
        <Fragment>
            {showSignInMfa === true ? <MfaValidation verification = {verificationId} hints = {hints} resolve={resolve}/>:
                <Button variant="outlined" startIcon={<GoogleIcon />} onClick={handleGoogleSignIn}>
                    GOOGLE
                </Button>
            }
        </Fragment>
    );

}

export default GoogleSignIn;