import React, { useState, useEffect } from 'react';

export function useCurrentPath() {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    localStorage.setItem('currentPath', currentPath);
  }, [currentPath]);

  return currentPath;
}