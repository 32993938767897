import react,{useEffect, useState,useContext } from "react";
import { useNavigate,useSearchParams } from 'react-router-dom';
import { 
  Button, 
  Typography, 
  Box, 
  Grid, 
  useMediaQuery, 
  FilledInput, 
  InputAdornment, 
  IconButton, 
  InputLabel, 
  FormControl, 
  Alert } from "@mui/material";
import Carousel from '../../../Components/ToolKits/carousel/Carousel';
import SEO from "../../../utils/SEO";
import axios from 'axios';
import { auth } from "../../../firebase/Firebase";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthFirebaseContext } from '../../../createContext/AuthFirebaseContext';
import { handleFirebaseAuthLoginError } from "../../../utils/authFunctions";
import MfaValidation from "./MfaValidation";
import {
  getMultiFactorResolver,
  PhoneAuthProvider,
  signInWithEmailAndPassword,

} from "firebase/auth";
import dark from '../../../themes/dark';
import light from '../../../themes/light';
import { createTheme, ThemeProvider,useTheme } from '@mui/material/styles';

const RootUser = (props )=>{
    const { recaptchaToken } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { user  } = useContext(AuthFirebaseContext);

    const [initialTheme, setInitialTheme] = useState(localStorage.getItem('userthemes'));
    const themetest = initialTheme === "light" ? createTheme(light) : createTheme(dark) ;
    
    const theme = useTheme();
    const isXs = useMediaQuery('(max-width:900px)');
  
    const [error, setError] = useState('');
    const refererRoute = searchParams.get('redirect_uri');
    const email = searchParams.get('email');

    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loginBtnClick, setLoginBtnClick] = useState(false);

    // mfa
    const [recaptcha, setRecaptcha] = useState(recaptchaToken);
    const [verificationId, setVerificationId] = useState('');
    const [verificationCode, setVerificationCode] = useState(null);
    const [resolve, setResolve] = useState(null);
    const [validationCodeError, setValidationCodeError] = useState(null);
    const [hints, setHints] = useState();
    const [showSignInMfa, setShowSignInMfa] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    useEffect(() => {
      if(user){
        navigate("/accessfeature");
      }
    }, []);

    const handleSignIn = async (e) => {
 
      setLoginBtnClick(true);
      setError("");
      
      try {
        // Sign in the user with email and password
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const idToken = await userCredential.user.getIdToken();
        
        const userData = {idToken};
        axios
          .post('https://us-central1-benhass-65ea4.cloudfunctions.net/idectsaccountserver/assigncookie', userData)
            .then((response) => {
              console.log(response);
              document.cookie = `_id-tnt_GT4RTY5639UNCGDTSA=${response.data.cookie}; max-age=432000000; domain=.idects.com; path=/; SameSite=None; Secure`;
              // document.cookie = `_lid_4RTY5639UNCGDTSLH=${response.data.cookie}; max-age=3600;  path=/; Secure`;
              document.cookie = `tntStp=false; max-age=432000000; domain=.idects.com; path=/; SameSite=None; Secure`;
              console.log('cookie made');
            })
            .catch((error) => {
              console.log(error);
              console.log("error in getting data");
            });
  
        //const result = await sessionLogin({idToken});
        // Redirect the user to the home page
        const refererRoute = searchParams.get('redirect_uri');
  
        if (refererRoute !== '') {
          window.location.replace(`${refererRoute}?referer=accounts&authaction=successlogin`);
        }else{
          // means the person come to accounts directly
          navigate("/accessfeature");
          // console.log("c");
        }
       
      } catch (error) {
        if (error.code == 'auth/multi-factor-auth-required') {
          // The user is a multi-factor user. Second factor challenge is required.
          const resolver = getMultiFactorResolver(auth, error);
          setResolve(resolver);
          // console.log(resolver);
          setHints(resolver.hints[0]);
        
          setShowSignInMfa(true);
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session
          };
  
          const phoneAuthProvider = new PhoneAuthProvider(auth); 
  
          phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptcha)
          .then(function (verificationId) {
              // verificationId will be needed for sign-in completion.
              setVerificationId(verificationId);
              setRecaptcha(null);
          });
  
        } else if (error.code !== 'auth/multi-factor-auth-required') {
            setLoginBtnClick(false);
            handleFirebaseAuthLoginError(error.code);
            
        }
      }
  
      // As httpOnly cookies are to be used, do not persist any state client side.
      //firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
      //setPersistence(auth, inMemoryPersistence);
    };

    return(
      <ThemeProvider theme={themetest}>
        <SEO
          title="Root signin | iDects "
          description="sign in with your credentials to access your account"
          name="iDecide Techscape"
          type="sign in" 
        />
        <div id="recaptcha-container"></div>
       
        <Box
          sx={{
            display: "flex",
            height: "100dvh",
            alignItems: "center",
            justifyContent: "center",
               
          }}
        > {showSignInMfa === true ? <MfaValidation verification = {verificationId} hints = {hints} resolve={resolve}/>:
            <Grid container spacing={2} sx={{ maxWidth: 960, boxShadow: isXs === false ? 3:0 , borderRadius:"6px" }}>
            <Grid item xs={12} md={4} sx={{  borderRadius:"6px 0 0 6px", }}>
              {isXs === false ?
              <Box >
                  <Carousel  />
              </Box>
              :""
              }
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                  sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "left",
                  p: 4,
                  pt: 1
                  }}
                >
                    
                    {error !== "" && <Alert severity="error" sx={{ mb:2}}>{error}</Alert> }
                    <Typography variant="body2" sx={{ fontWeight:700, fontSize:20, mb: 1 }}>{ email }</Typography>
                    <Typography variant="body2">Continue with Password:</Typography>
                   
                    <FormControl sx={{ mt: 1, width: '100%' }} variant="filled" style={{backgroundColor:"#212121"}}>
                      <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                      <FilledInput
                          
                        id="filled-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                          }
                        />
                    </FormControl>

                    <Button
                        onClick={handleSignIn}
                        disabled = { loginBtnClick === true ? true :false }
                        type="button"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        { loginBtnClick === true ? 'Verifying, Almost done' :'Sign In As Root User' }
                    </Button>
                  <Typography variant="caption" sx={{ mt: 2 }}>
                  By clicking 'Continue' you agree with our Terms of Use
                  & have read about we collect and how we handle it in our Privacy
                  Policy.
                  </Typography>
                </Box>
            </Grid>
          </Grid>}
        </Box>
        
      </ThemeProvider>
    )


}
export default RootUser;