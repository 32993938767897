import initialState from './initialState';

export default function uiReducer(state = initialState.ui, action) {
  switch (action.type) {
    case 'GET_THEME':
      return {
        ...state,
        theme: action.payload,
      };
    case 'SET_THEME':
      return {
        ...state,
        theme: action.payload,
      };
    case 'GET_SELECTED':
      return {
        ...state,
        selectedlink: action.payload,
      };
    case 'SET_SELECTED':
      return {
        ...state,
        selectedlink: action.payload,
      };
    default:
      return state;
  }
}
