import React, { useState, useEffect } from 'react';

export default function NavContent({ content }) {
  // Determine which content component to render based on the content prop
  switch (content) {
    case 'Apps':
      return <Apps content={content} />;
    case 'Suites':
      return <Suites content={content} />;
    case 'Zoho':
      return <Zoho content={content} />;
    case 'Mobile':
      return <Mobile content={content} />;
    case 'Marketplace':
      return <Marketplace content={content} />;
    default:
      return null;
  }
}


function Apps({ content }){

  return (
    <div>
      <h2>{content} Container</h2>
       Add the content for the apps container here 
    </div>
  );
}

function Suites({ content }){

  return (
    <div>
      <h2>{content} Container</h2>
       Add the content for the suites container here
    </div>
  );
}

function Zoho({ content }){

  return (
    <div>
      <h2>{content} Container</h2>
       Add the content for the zoho container here 
    </div>
  );
}

function Mobile({ content }){

  return (
    <div>
      <h2>{content} Container</h2>
       Add the content for the mobile container here 
    </div>
  );
}

function Marketplace({ content }){

  return (
    <div>
      <h2>{content} Container</h2>
       Add the content for the Marketplace container here 
    </div>
  );
}