const initialState = {
  base: {
    todos: [],
    test: false,
    theme: 'dark',
  },
  ui: {
    theme: 'dark',
    selectedlink: 0,
  },
  connect: {
    status: window.navigator.onLine,
    localstorage: true, // pick this from electron storage db as its mainly for config s no sqlite
    dataSource: 'onlinefirst', // options {onlinefirst, offlinefirst}
  },
};

export default initialState;
