import React, { useState,  useCallback, lazy , Suspense, useEffect,useContext  } from 'react';
import {
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { motion } from 'framer-motion';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import dark from './themes/dark';
import light from './themes/light';
import SignIn from './Pages/Auth/SignIn';
import SignUp from './Pages/Auth/SignUp';
import IamSignIn from "./Pages/Auth/IamSignIn";
import Verfication from './Pages/Auth/Verfication';
import Featurette from './Pages/Landing/Featurette';
import Mfa from './Pages/Landing/Mfa';
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
//import BackToTop from './Pages/Landing/BackToTop';
//import AppSelect from './Pages/Main/AppSelect';
import NoMatch from './Pages/NotFound/NoMatch';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/montserrat';
import "@fontsource/barlow"; // Defaults to weight 400
import "@fontsource/barlow/400.css"; // Specify weight 100-900
import "@fontsource/barlow/500.css"; // Specify weight 100-900
import "@fontsource/barlow/700.css"; // Specify weight 100-900
import "@fontsource/barlow/800.css"; // Specify weight 100-900
import "@fontsource/barlow/900.css"; // Specify weight 100-900
import "@fontsource/barlow/400-italic.css"; // Specify weight and style [italic,normal]
import AuthAccessRoute from './Components/Auth/AuthAccessRoute';
import OnboardingCheck from './Components/Auth/OnboardingCheck';
import TenantConfigAccess from './Components/Auth/TenantConfigAccess.jsx';
import PrivateRoute from './Components/Auth/PrivateRoute';
//import { UserAuthContextProvider } from './createContext/userAuthContext';
//import { userAuthContextProvider } from './createContext/UserAuthContext';
import { AuthFirebaseContextProvider, AuthFirebaseContext } from './createContext/AuthFirebaseContext';
import './firebase/Firebase';
import { actionCreators, uiActionCreators } from './store/actions';
import { useCurrentPath } from './Components/ToolKits/useCurrentPath';
import { HelmetProvider } from 'react-helmet-async';
import RootUser from './Pages/Auth/slices/RootUser';
import Offline from './utils/Offline';
                                                                                                                                                                                                                                                                                                         
// configs imports
const SettingsLayout = lazy(() => import('./Components/Layout/config/SettingsLayout.jsx'));
const ErrorPage = lazy(() => import('./Pages/Configs/ErrorPage.jsx'));
const Welcome = lazy(() => import('./Pages/Configs/index'));
const PersonalInfo = lazy(() => import('./Pages/Configs/Personal/PersonalInfo'));
const Email = lazy(() => import('./Pages/Configs/Email/Email'));
const Contact = lazy(() => import('./Pages/Configs/Contact/Contact'));
const Password = lazy(() => import('./Pages/Configs/Password/Password'));
const TwoFactor = lazy(() => import('./Pages/Configs/TwoFactor/TwoFactor'));
const MfaSetUp = lazy(() => import('./Pages/Configs/TwoFactor/MfaSetUp'));
const SettingsErrorPage = lazy(() => import('./Pages/Configs/SettingsErrorPage'));
//const MarkdownPreview = lazy(() => import('./MarkdownPreview.js'));

//onboarding imports
const Onboarding = lazy(() => import('./Pages/Onboard/Onboarding.jsx'));
// tenant configs
const TenantLayout = lazy(() => import('./Components/Layout/tenant/TenantLayout.jsx'));
const TenantWelcome = lazy(() => import('./Pages/TenantConfigs/index'));
const TenantUserInfo = lazy(() => import('./Pages/TenantConfigs/Personal/TenantUserInfo'));


window.onerror = function(error, url, lineNumber, columnNumber, errorObj) {
  console.error(error);

  // Log the error to Firebase Crashlytics./or other reporting service like sentry
  //firebase.crashlytics().recordError(error);
  // Display a fallback UI.
  //document.getElementById('fallback-ui').style.display = 'block';
};

const PageLayout = ({ children }) => children;

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.5,
};

const AnimationLayout = () => {
  const { pathname } = useLocation();
  return (
    <PageLayout>
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet />
      </motion.div>
    </PageLayout>
  );
};

const themeSetter = (options) => {
  let themeselector;

  if (options === 'light') {
    themeselector = light;
  } else if (options === 'dark') {
    themeselector = dark;
  }
  return themeselector;
};

function fallbackAuthRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong in login activities.. i will style later:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}




export default function App() {
  const dispatch = useDispatch();
  const uiState = useSelector((state) => state.ui);
  const uiThemeMode = themeSetter(uiState.theme);
  const [themer, setThemer] = useState(null);
  const [lastVisitedPage, setLastVisitedPage] = useState(null);
  const [initialTheme, setInitialTheme] = useState();
  const { addTodo, removeTodo } = bindActionCreators(actionCreators, dispatch);
  const { getTheme, setTheme } = bindActionCreators(uiActionCreators, dispatch);
  const [userDetails, setUserDetails] = React.useState();
  const [error, setError] = React.useState(null);
    // Use state to store the network status and the snackbar visibility
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const [open, setOpen] = React.useState(false);

 
  const navigate = useNavigate();
  const currentPath = useCurrentPath();

  // console.log("theme from app", uiThemeMode);


  useEffect(() => {

    const fetchtheme = localStorage.getItem('userthemes'); 

    if(!fetchtheme){
      setInitialTheme("light");
      localStorage.setItem('userthemes', "light"  );
    }else{
      setInitialTheme(fetchtheme);
    }
    
    // console.log(fetchtheme);
  }, []);

   useEffect(() => {
    // Define a function to handle the storage event
    const handleStorageChange = (event) => {
      if (event.key === 'userthemes') {
        setInitialTheme(event.newValue); // Update state with the new value
      }
    };

    // Add an event listener for the storage event
    window.addEventListener('storage', handleStorageChange);

    // Return a cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); // Pass an empty array as the dependency list to run only once

   const toggleThemeColorMode = () => {
    //setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
    setInitialTheme((prev) => (prev === 'dark' ? 'light' : 'dark'));
    //localStorage.setItem('userthemes', initialTheme  );
    //console.log(mode);
  }

  // const themetest = initialTheme === "light" ? createTheme(light) : createTheme(dark) ;


  

  //const { loadingComponent } = useContext(AuthFirebaseContext);
  //const { showBoundary, resetBoundary } = useErrorBoundary();
  // Define a fallback UI component.
  function FallbackUI() {
    return <h1>Something went wrong!</h1>;
  }

  const logError = (errorr, info) => {
    // Do something with the error, e.g. log to an external API
    //logging to file or sewnding to sentry or fireanalytics or so
  };

  const handleAuthError = useCallback((error, info) => {
    console.log(error);
    setError(error);
    //later return ui ....
  }, [setError]);

  const loading = () =>{
    return '<div>loading</div>'
  }

  const LoadComponent = ({ component: Component }) => (
    <ErrorBoundary fallback={fallbackAuthRender} onError={logError}>
      <Suspense fallback={loading()}>
          <Component />
      </Suspense>
    </ErrorBoundary>
  );

  // Define a function to handle the network status change
  const handleStatusChange = () => {
    setIsOnline(navigator.onLine);
    // Show the snackbar when offline and hide when online
    setOpen(!navigator.onLine);
  };

  // Use effect to add and remove event listeners for the network status
  React.useEffect(() => {
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, []);
  

  return (
    <HelmetProvider>
    
        <AuthFirebaseContextProvider>
          <Offline offline={open} notifyMessage={isOnline} />
          <Routes>

            <Route element={<AnimationLayout />}>

              <Route path="/" element={<ErrorBoundary fallback={fallbackAuthRender} onError={logError}><SignIn /></ErrorBoundary>} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/tenantiamverifysign" element={<IamSignIn />} />
              <Route path="/tenantrootverifysign" element={<RootUser />} />

              <Route element = {<PrivateRoute/>} >
                <Route path="/uncomfirmedverfitcationotp" element={<ErrorBoundary fallback={<div>Something went wrong</div>}><Verfication /></ErrorBoundary>} />
              </Route>

              <Route element={<AuthAccessRoute />} >
                <Route path="/enrollsecuritywelcome" element={<ErrorBoundary fallback={<div>Something went wrong</div>}><Mfa /></ErrorBoundary>} />
                <Route path="/accessfeature" element={<ErrorBoundary fallback={<div>Something went wrong</div>}><Featurette /></ErrorBoundary>} />
                
              </Route>
              <Route element={<OnboardingCheck />} >
                <Route path="/tenantonboarding" element={<ErrorBoundary fallback={<div>Something went wrong in onboarding</div>}><Onboarding /></ErrorBoundary>} />
              </Route>

              <Route  element={<AuthAccessRoute />} errorElement={<ErrorPage />}>
                <Route  path="/configs" element= {<LoadComponent component={SettingsLayout}  />} >
                  <Route  errorElement={<SettingsErrorPage />}>
                    <Route index element={<Welcome />} />
                    <Route path="profile/personaldetails"  element = <LoadComponent component={PersonalInfo}/> />
                    <Route path="profile/email"  element = <LoadComponent component={Email}/> />
                    <Route path="profile/contactnumbers"  element = <LoadComponent component={Contact}/> />
                    <Route path="security/passwordupdate"  element = <LoadComponent component={Password}/> />
                    <Route path="auth/mfa"  element = <LoadComponent component={TwoFactor}/> />
                    <Route path="auth/mfa/config"  element = <LoadComponent component={MfaSetUp}/> />
                    <Route  path="*" element={<ErrorPage />} />
                  </Route>
                  {/*
                  <Route  errorElement={<SettingErrorPage />}>
                  
                  <Route path="contacts/:contactId"  element: <LoadComponent component={Contacts}/> />
                  <Route path="contacts/:contactId/edit" element={<EditContact />}  />
                  <Route path="contacts/:contactId/destroy" action={destroyAction} />
                    */}
                </Route>
              </Route>

              <Route  element={<TenantConfigAccess />} errorElement={<ErrorPage />}>
                <Route  path="/tenantconfig" element= {<LoadComponent component={TenantLayout}  />} >
                  <Route  errorElement={<SettingsErrorPage />}>
                    <Route index element={<TenantWelcome />} />
                    <Route path="profile/personaldetails"  element = <LoadComponent component={TenantUserInfo}/> />
                    <Route  path="*" element={<ErrorPage />} />
                  </Route>
                  {/*
                  <Route  errorElement={<SettingErrorPage />}>
                  
                  <Route path="contacts/:contactId"  element: <LoadComponent component={Contacts}/> />
                  <Route path="contacts/:contactId/edit" element={<EditContact />}  />
                  <Route path="contacts/:contactId/destroy" action={destroyAction} />
                    */}
                </Route>
              </Route>
            </Route>

            <Route path="*" element={<NoMatch />} />


          </Routes>
        </AuthFirebaseContextProvider>

    </HelmetProvider>
  );
}

