import React, { useState, useContext, useEffect } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { AuthFirebaseContext } from "./../../createContext/AuthFirebaseContext";
import { doc, getDoc } from "firebase/firestore";
import { auth,app,fireStore, } from "../../firebase/Firebase";

const OnboardingCheck = ({ children }) => {

    const { user, userInfo } = useContext(AuthFirebaseContext);
    // const [userInfos, setUserInfos] = useState(null);

    /*
    useEffect(()=>{
        const docRef = doc(fireStore, "users", user.uid);
        getDoc(docRef).then((docSnap)=>{
        if (docSnap.exists()) {
            console.log("Document data from onboarding check:", docSnap.data());
            setUserInfos(docSnap.data());
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }
        }).catch((error)=>{
        // navigate  to another place since internet is down and page relies on internet connectivity
        console.log(error);
        });
    },[]);
    */
    //console.log("Check user in PrivateFB: ", user);
    if (user && user.emailVerified && user.tenantId === null) {
        //now fech from firestore user tenant stuff 
        // let ownerStatus = userInfo.tenantOwner;
        if(userInfo !== null){
            if(userInfo.tenantOwner === null || userInfo.tenantOwner === false ){
                // now check tenantRegistry
                console.log('verified from onboarder check');
                return children ? children : <Outlet />;
            }else{
                console.log('cant validate tenancy status onboarding ');
                return <Navigate to="/accessfeature" />;
            }
        }
    }else if (user && !user.emailVerified){
        console.log('email not verified from authaccessroute');
        return <Navigate to="/uncomfirmedverfitcationotp" />;
    }else{
        return <Navigate to="/" />;
    }
};

export default OnboardingCheck;