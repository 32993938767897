import react, {useState, Fragment } from "react";
import OtpInput from 'react-otp-input';
import {
    Button,
    Box,
    Typography, 
   Alert
} from '@mui/material';
import {
    PhoneAuthProvider,
    PhoneMultiFactorGenerator
  } from "firebase/auth";

const MfaValidation = (props) =>{
    const {verification, hints, resolve} =props;

    const [validateBtnClick, setValidateBtnClickBtnClick] = useState(false);
    const [validationCodeError, setValidationCodeError] = useState(null);
    const [OTP, setOTP] = useState(null);

    const handleChangecode = (OTP) => {
        //const newCode = event;
        setOTP(OTP);
        console.log(OTP);
    };

    const vadidateMfa = async () =>{

        //console.log(OTP);
        //setVerificationCode(OTP);
        setValidateBtnClickBtnClick(true);
       
        if( /^\d{6}$/.test(OTP) ){
          try{
            // Ask user for the SMS verification code. Then:
            const cred = PhoneAuthProvider.credential(verification, OTP);
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    
             // Complete sign-in.
             //console.log('allowed');
            return resolve.resolveSignIn(multiFactorAssertion)
          }catch(err){
            console.log(err);
          }
        }else{
          console.log('invalid code format');
          setValidateBtnClickBtnClick(false);
          setValidationCodeError('Invalid code format');
        }
    
    };  

    return(
        <Box sx={{ margin:'5% auto', maxWidth: '60%'}}>
            <Typography variant='h4' component='h4'>Authenticate 2FA </Typography>
            <Typography variant='body1' component='p'>
                We have sent a code to { hints.phoneNumber }, your {hints.displayName}. Plese enter the code below to continue 

            </Typography>
            {validationCodeError && <Alert severity="error">{validationCodeError}</Alert>} 
            <Box sx={{ paddingTop: '50px', paddingBottom: '20px' }}>
                <OtpInput
                    value={OTP}
                    onChange={handleChangecode}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    containerStyle ={{width: '100%'}}
                    inputStyle={{width: '50px', height: '50px'}}
                />
            </Box>

            <Button variant='contained' onClick={vadidateMfa}  disabled = { validateBtnClick === true ? true :false }> { validateBtnClick === true ? 'Validating ...' :'Authenticate 2FA' }</Button>
        </Box>
    );
}

export default MfaValidation