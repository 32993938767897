// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCJYa-kCUDSsNYeSeWmrzApB8th7w98TUQ",
  authDomain: "benhass-65ea4.firebaseapp.com",
  projectId: "benhass-65ea4",
  storageBucket: "benhass-65ea4.appspot.com",
  messagingSenderId: "170828153762",
  appId: "1:170828153762:web:d33eeef72819399e90a8fb",
  measurementId: "G-9P93QQ6XNV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const fireStore = getFirestore(app);
export const auth = getAuth(app);
const functions = getFunctions(app); 
const storage = getStorage(app);

export { fireStore, app , functions, storage};
//export default app;