import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {IconButton} from '@mui/material';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';


function ThemeSwitchNew(props) {
  const theme = useTheme();
  const [mode, setMode ]  = React.useState('');
  //const { initialTheme, toggleThemeColorMode } = React.useContext(ThemeContext);

  React.useEffect(() => {
    
    setMode(props.modeState);
    
  }, []);


  const iconStyles = {
    color: "f3f3f3",
    transition: 'color 0.3s ease-in-out',
  };

  const toggleColorMode = () => {
    //console.log(mode)
    if(props.modeState === "light"){
      setMode("dark");
      props.triggerTheme("dark");
    }else if(mode === "dark"){
      setMode("light");
      props.triggerTheme("light");
    }
    
    //setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
    //props.triggerTheme(mode);
    //localStorage.setItem('userthemes', mode  );
    //console.log(mode);
  }

  return (
    <Box sx={{ maxWidth: '32px' }}>
      <IconButton
        variant="text"
        onClick={toggleColorMode}
        aria-label="button to toggle theme"
      >
        {mode === 'dark' ? (
          <WbSunnyRoundedIcon style={{ ...iconStyles,color:theme.palette.text.primary  }} sx={{ color: theme.palette.text.primary }} />
        ) : (
          <ModeNightRoundedIcon style={{ ...iconStyles,color:theme.palette.text.primary }} sx={{ color: theme.palette.text.primary }} />
        )}
      </IconButton>
    </Box>
  );
}


export default ThemeSwitchNew;