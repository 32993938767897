import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MuiOtpInput } from 'mui-one-time-password-input';
import OtpInput from 'react-otp-input';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { createStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useSelector, useDispatch } from 'react-redux';
import { AuthFirebaseContext } from './../../createContext/AuthFirebaseContext';
import { getAuth } from "firebase/auth";
import { auth, app,fireStore, } from "../../firebase/Firebase";
import { multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, reauthenticateWithCredential,EmailAuthProvider,GoogleAuthProvider  } from "firebase/auth";



const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(+000) 00-000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Mfa = () => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [inputNumber, setInputNumber] = useState('(+000) 00-000-0000');
  const [verificationId, setVerificationId] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [reAuth, setReAuth] = useState(true);
  const [numAuthErr, setNumAuthErr] = useState(null);
  const [error, setError] = useState('');
  const [reAuthPassword, setReAuthPassword] = useState("");
  const { user } = useContext(AuthFirebaseContext);
  const [recaptcha, setRecaptcha] = useState(null);
  const [codeValErrorEntered, setCodeValErrorEntered] = useState(null);
  const [OTP, setOTP] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [btnEnroll, setBtnEnroll] = React.useState(false);
  //const { value, defaultCountry, onChange, classes } = props;
  //const auth = getAuth(app);
  const navigate = useNavigate();
  const theme = useTheme();
  
//const recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", undefined, auth);

  // const recaptchaVerifier = new RecaptchaVerifier('capcha-container', {
      //      "size": "invisible",
      //      "callback": () => {}
     //   }, auth);
  const [values, setValues] = React.useState({
    textmask: '(+100) 00-000-0000',
    numberformat: '1320',
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

    const handleChangecode = (OTP) => {
      //const newCode = event;
      setOTP(OTP);
      console.log(OTP);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      // Handle backdrop click event here.
      setReAuth(true);
      setOpen(false);
      
    }
    setReAuth(true);
    setOpen(false);
  };

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': () => {}
    }, auth);

    setRecaptcha(recaptchaVerifier);

    return () => {
      recaptchaVerifier.clear();
    }
  }, []);

  const onSolvedRecaptcha = (response) => {
  	console.log(response);
  }

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleReAuth = (e) => {
    //reauthenticate the user
    const credential = EmailAuthProvider.credential(user.email, reAuthPassword);
    reauthenticateWithCredential(user, credential).then(() => {
      // Update successful.
      console.log('u got it continue mfa');
      //enroll in mfa
      enroll();
      //allow otp in box view 
      setReAuth(false);
    }).catch((error) => {
      console.log(error);
      //setLoginAuthErr(error);
      handleFirebaseAuthLoginError(error.code);
      //setError(error.code)
      //setReAuth(false);
      //setBtnEnroll(true);
      // An error ocurred
      // ...
    });
  }

  const handleFirebaseAuthLoginError = (firebaseAuthError) => {
    switch (firebaseAuthError) {
      case "auth/email-already-in-use":
          setError('account is in use');
        break;
      case "auth/id-token-expired":
          setError('please refresh your browser an try again later');
        break;
      case "auth/id-token-revoked":
          setError('user account deleted');
        break;
      case "auth/invalid-email":
          setError('email address is not valid');
        break;
      case "auth/invalid-password":
          setError('password incorrect');
        break;
      case "auth/user-not-found":
          setError('user not found');
        break;
      default:
          setError('try again later or contact adminstrator');
        break;
    }
  };

  const handleMfaDiaglogue = () =>{
    //VALIDATE PHONE NUMBER BEFORE OPENNING DIALOGUE
    const isValid = validatePhoneNumber(inputNumber);
    //console.log(isValid);
    //console.log(user);
    //validation mobile number
    if (inputNumber !== '(+000) 00-000-0000' && isValid) {
      //console.log("The phone number is valid.");
      const providerId = user.providerData[0].providerId;
      if (providerId === "password") {
        setNumAuthErr(null);
        handleClickOpen();
        //setBtnEnroll(true);
        const verificationformat = formatPhoneNumber(inputNumber);
        setPhoneNumber(verificationformat);
        //this section code is only set adter validating password 
      } else if(providerId === "google.com") {
        // Create an instance of the Google provider object
        //const provider = new GoogleAuthProvider();
         // Get the ID token from the provider data
        const idToken = user.providerData[0].idToken;

        // Create a credential object with the ID token
        const credential = GoogleAuthProvider.credential(idToken);

         // Reauthenticate the user with the credential
        reauthenticateWithCredential(user, credential)
          .then(() => {
            // The user is successfully reauthenticated disable password input in dialogue box
            setReAuth(false);
            
            //send otp. since phone number avilable
            enroll();
            //open dialogue to enter otp
            handleClickOpen();
            //console.log("User reauthenticated");
          })
          .catch((error) => {
            // Handle error
            alert(error.code);
            console.error(error);
          });
        //no password input needed but privider popup window
        
      }else{
        alert("MFA enroll not possible at this time. Please againlater");
      }
      

    } else {
      setNumAuthErr('Invalid mobile number format')
    }

  }


  function validatePhoneNumber(input) {
     // Define a regular expression pattern to match the format
    const pattern = /^\(\+233\) \d{2}-\d{3}-\d{4}$/;
    // Check if the input matches the pattern
    const isValid = pattern.test(input);
  
    return isValid;
  }

  const enroll = async () => {
    
  	const multiFactorSession = await multiFactor(user).getSession();
  	// Specify the phone number and pass the MFA session.
  	const phoneInfoOptions = {
  	  phoneNumber: phoneNumber,
  	  session: multiFactorSession
  	};

  	const phoneAuthProvider = new PhoneAuthProvider(auth); 

  	//const verification = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptcha);

    phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptcha)
      .then(function (verificationId) {
          // verificationId will be needed to complete enrollment.
        setRecaptcha(null);

        setVerificationId(verificationId);
        //console.log('success');

       
        //console.log(verificationId);
      }).catch((error)=>{ 
        console.log(error);
        setBtnEnroll(false);
      });

    
   
  };

  function formatPhoneNumber(input) {
    // Remove braces, spaces, and dashes from the input string
    const formattedNumber = input.replace(/[\s()\-]/g, '');
  
    return formattedNumber;
  }

  const testenroll = () =>{
    //console.log(inputNumber);
    const verificationformat = formatPhoneNumber(inputNumber);
    setPhoneNumber(verificationformat);
    console.log(phoneNumber);
    //handleClickOpen();
  };



  const validate = async () =>{

    //console.log(verificationCode);
    setVerificationCode(OTP);

    if(verificationCode !== '' && verificationCode.length >= 6 ){

      try{
        const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        multiFactor(user).enroll(multiFactorAssertion, "official contact number").then(function (results) {
          console.log(results);
          handleClose();
          navigate('/accessfeature');

        }).catch((error)=> { 
          
          if (error.code === 'Timeout') {
            // Handle the timeout error
            alert('time elapsed. Request for a new code');
          } else {
            // Handle other errors
            //alert('checl console');

            if(error.code === "auth/invalid-verification-code"){
              setCodeValErrorEntered('Invalid OTP Code');
            }
            console.log(error.code);
          }
          
        });

        
      }catch(err){

        alert('MFA enrollment failed. Please try again in a few miniutes');
        console.log(err);
      }
    }

   
    //console.log('enroll');
    
  };

  const cancelMfa =(e) =>{
    e.preventDefault();
    navigate('/accessfeature');
  } 
  return (
    <React.Fragment>
      <CssBaseline />

      <Container sx={{ paddingTop:'120px'}} >

        <Grid conatiner spacing={2}>
          <Grid item xs={12} md={12}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h3'>Add LOGO</Typography>
                </Grid> 
              </Grid> 
            </Box>
            
            <Box sx={{ marginTop:'20px'}}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant='h3' component='h3' >Secure Your Account</Typography>
                  <Typography variant='p' component='p' sx={{ marginTop:'15px'}}>
                    Passwords alone are not enough to secure your account efficiently.
                  </Typography>

                  <Typography variant='p' component='p' sx={{ marginTop:'15px'}}>
                    improve your security by enabling a multiFactor authentication by entering your phone phone Number
                    below and recieve an sms code.

                    You can always go to settings and activate it later. 
                  </Typography>
                  <Box  sx={{ marginTop: '20px'}}>
                      {numAuthErr && <Alert severity="error">{numAuthErr}</Alert>}
                     <FormControl variant="standard">
                        <InputLabel htmlFor="formatted-text-mask-input">Phone Number</InputLabel>
                          <Input
                            value={inputNumber}
                            name="mobile_number"
                            id="mobile_number"
                            inputComponent={TextMaskCustom}
                            onChange={(e) => setInputNumber(e.target.value)}
                        />
                      </FormControl><br /><br />
                    <Button variant='contained' onClick={handleMfaDiaglogue} disabled = { btnEnroll === true ? true : false }>
                      { btnEnroll === true ? 'PLEASE WAIT' : 'ENROLL IN MFA' }
                    </Button>
                   </Box>   
                  <Typography variant='p' component='p'>
                    
                  </Typography >
                </Grid>
                <Grid item xs={4}>
                decorative image vector art
                </Grid>
                
              </Grid>
            </Box>

            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button variant='text' onClick={cancelMfa} sx={{ marginTop: '15px'}}>
                     Will configure it later
                  </Button>
                </Grid> 
              </Grid> 
            </Box>

           </Grid>
        </Grid>
      </Container>
    	 <Dialog
          open={open}
          TransitionComponent={Transition}
          disableEscapeKeyDown={true}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{reAuth === true ? 'Please enter your password':"Please the one time code"}</DialogTitle>
          
            { reAuth === true ? 
              <DialogContent>
                <DialogContentText>
                  To continue with the process, please provide your current password. This will not
                  modify or replace your password.
                </DialogContentText>
                 {error && <Alert severity="error">{error}</Alert>}
                
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Password"
                  type="password"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setReAuthPassword(e.target.value)}
                />
              </DialogContent>
            : 
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Enter the secret otp sent your mobile 
                </DialogContentText> 
                {codeValErrorEntered && <Alert severity="error">{codeValErrorEntered}</Alert>}
                  <OtpInput
                  value={OTP}
                  onChange={handleChangecode}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                  containerStyle ={{minwidth: '100%'}}
                  inputStyle={{width: '50px', height: '50px'}}
                />
              </DialogContent>
            }
            
          
          <DialogActions>
          { reAuth === true ? 
            <Button onClick={handleReAuth}>ReAuthenticate</Button>:
            <Button onClick={validate}>Validate</Button> 
          }
          <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>

    <div>


    	<div id="recaptcha-container"></div>
      
    </div>
    </React.Fragment>
  );
};

export default Mfa;
