import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AuthFirebaseContext } from './../../createContext/AuthFirebaseContext';
import { getAuth } from "firebase/auth";
import { auth, app,fireStore, } from "../../firebase/Firebase";
import { multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier } from "firebase/auth";
import Button from '@mui/material/Button';
import { Link, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';



const Verfication = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  
  const { user, verifyIfUserIsEnrolled, verifyUserEmail  } = useContext(AuthFirebaseContext);

  const currentUser = user;


  useEffect(() => {
    
    if (user.emailVerified) {
      setIsVerified(true);

      <Navigate to="/accessfeature"/>
    }
    

    return () => setIsVerified();
  }, []);

  useEffect(() => {
    if (user && !user.emailVerified) {
      const interval = setInterval(() => {
        user.reload().then(() => {
          if (user.emailVerified) {
            clearInterval(interval);
            window.location.reload();
          }
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [user]);

  async function sendEmail() {
      if (currentUser) {
          const response = await verifyUserEmail(currentUser);

          if (response) {
              alert('An Email has been sent to you');
          }else {
              alert('we could not send the email now. please try again later');
          }
      }
  }

  return (
    <React.Fragment>
      <CssBaseline />

      <Container sx={{ paddingTop:'120px'}} >

        <Grid conatiner spacing={2}>
          <Grid item xs={12} md={12}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h3'>Add LOGO</Typography>
                </Grid> 
              </Grid> 
            </Box>
            
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant='h2' component='h2' >Account Email Confirmation</Typography>
                  <Typography variant='p' component='p' sx={{ marginTop:'15px'}}>
                    The primary address associated with your iDecideTechsacpe  (Puprle Organiser) is not verified yet.
                    Please very it now to confirm your account. 
                  </Typography>

                  <Typography variant='p' component='p' sx={{ marginTop:'15px'}}>
                    Please check your email inbox associated this account and
                    find the confirmation email and click on the link.

                    Just u did not recieve it, click on the button below to resend it.
                  </Typography>
                  
                  <Button variant='contained' onClick={sendEmail} sx={{ marginTop: '15px'}}>
                    { currentUser.email }
                  </Button>
                  <Typography variant='p' component='p'>
                    
                  </Typography >
                </Grid>
                <Grid item xs={4}>
                 decorative image vector art
                </Grid>
                
              </Grid>
            </Box>

           </Grid>
        </Grid>
      </Container>

    <div>
   
         {
              currentUser && currentUser.emailVerified && !verifyIfUserIsEnrolled(currentUser) &&
              <Link  to='/mfa'>
                  Activate the multifactor authentication
              </Link>
          }
                
    </div>
    </React.Fragment>
  );
};

export default Verfication;
