import React, { useState, useContext, useEffect } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { AuthFirebaseContext } from "./../../createContext/AuthFirebaseContext";
import { doc, getDoc } from "firebase/firestore";
import { auth,app,fireStore, } from "../../firebase/Firebase";

const TenantConfigAccess = ({ children }) => {

    const { user } = useContext(AuthFirebaseContext);

    //console.log("Check user in PrivateFB: ", user);
    if (user && user.emailVerified && user.tenantId !== null) {
        //now fech from firestore user tenant stuff 
        // console.log("eiiiiiiiiiiiiiiiiiiiiiiiiii",  userInfo)
        return children ? children : <Outlet />;
        
    }else if (user && !user.emailVerified){
        console.log('email not verified from authaccessroute');
        return <Navigate to="/uncomfirmedverfitcationotp" />;
    }else{
        return <Navigate to="/" />;
    }
};

export default TenantConfigAccess;