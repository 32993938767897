import React, { useState, useEffect,forwardRef } from "react";
import { signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../firebase/Firebase";
import axios from 'axios';
import { Button, TextField, Stack, FormControl, Input, InputLabel, Box  } from "@mui/material";
import { IMaskInput } from 'react-imask';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  multiFactor,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  setPersistence,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  inMemoryPersistence
} from "firebase/auth";

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(+233) 24-6757-282"
      definitions={{
        '+': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const PhoneAuth = (props) => {
  const { signInConfig} = props;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [confirmationResult, setConfirmationResult] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [recaptcha, setRecaptcha] = useState(null);
  const [showCodeBox, setShowCodeBox] = useState(false);
  
  const tenantId= signInConfig.tenantId;

  useEffect (() => {
    
    const recaptchaVerifier = new RecaptchaVerifier('phoneauth', {
      'size': 'invisible',
      'callback': () => {},
      'expired-callback': () => {
        alert("Recaptchar Error. Reload Page");
      }
    }, auth);

    setRecaptcha(recaptchaVerifier);

    return () => {
      recaptchaVerifier.clear();
    };
  }, []);



  const handleSignIn=()=>{
    auth.tenantId = tenantId;
    // sign in with phone number and handle the result
    signInWithPhoneNumber(auth, phoneNumber, recaptcha)
      .then((result) => {
        // SMS sent. Prompt user to type the code from the message
        setConfirmationResult(result);
        setShowCodeBox(true);
      })
      .catch((error) => {
        // Error; SMS not sent
        console.error(error);
      });
  }


  const handleChange = (event) => {
    setPhoneNumber(event.target.value);
  };  

  const handleCodeChange = (e) => {
    // update the confirmation code state
    setConfirmationCode(e.target.value);
  };

  const handleCodeSubmit = () => {
    // confirm the code and sign in the user
    confirmationResult
      .confirm(confirmationCode)
      .then((userCredential) => {
        // User signed in successfully.
        const user = userCredential.user;

        const idToken = userCredential.user.getIdToken();
        // let tenantId  = signInConfig.tenantId;
        const userData = {idToken,tenantId };

        axios
        .post('https://us-central1-benhass-65ea4.cloudfunctions.net/idectsaccountserver/tenantassigncookie', userData)
            .then((response) => {
              //console.log(response);
              // Get the JWT from the response
              const token = response.data.tId;
              document.cookie = `_id-tnt_GT4RTY5639UNCGDTSA=${response.data.cookie}; max-age=432000000; domain=.idects.com; path=/; httpOnly, SameSite=None; Secure`;
              // document.cookie = `_lid_4RTY5639UNCGDTSLH=${response.data.cookie}; max-age=3600;  path=/; Secure`;
              // Store the JWT in a cookie with httpOnly and secure flags
              document.cookie = `tidtoken=${token}; max-age=432000000; domain=.idects.com; path=/; SameSite=None;`;
              document.cookie = `tntStp=true; max-age=432000000; domain=.idects.com; path=/; SameSite=None; Secure`;
              console.log('cookie made for both');
            
            })
            .catch((error) => {
              console.log(error);
              console.log("error in getting data");
            });
    

        const refererRoute = searchParams.get('redirect');

        if (refererRoute !== '') {
            switch (refererRoute) {
                case "idectsindex":
                window.location.replace(`http://idects.com?auth=true&referer=accounts&occ=tnt`);
                break;
            
                default:
                window.location.replace(`http://idects.com`);
                break;
            }
        }else{
            // means the person come to accounts directly
            navigate(`/accessfeature`);
        }
        //console.log(user);
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        console.error(error);
      });
  };

  return (
    <div>
      { showCodeBox === true ? 
      
        <Box component="form" noValidate>
          <TextField
            label ="Enter the confirmation code"
            variant="filled"
            type="text"
            value={confirmationCode}
            onChange={handleCodeChange}
          />
          <Button type="button" onClick={handleCodeSubmit}>
            Confirm
          </Button>
        </Box>
      :
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 2 }} sx={{ mt:1, mb:1}} style={{ display:"none" }}>
        <FormControl
          variant="standard"
          sx={{ width: "70%", bgcolor: "yellow" }}
          >
          <InputLabel
              htmlFor="formatted-text-mask-input"
              sx={{ color: "blue" }}
          >
              Phone Number
          </InputLabel>
          <Input
              value={phoneNumber}
              onChange={handleChange}
              name="phone"
              id="formatted-text-mask-input"
              inputComponent={TextMaskCustom}
              sx={{ color: "green", "& .MuiOutlinedInput-notchedOutline": { borderColor: "red" } }}
          />
        </FormControl>
        <Button
          type="submit"
          id="phoneauth"
          variant="contained"
          sx={{ mt: 1, mb: 1 ,float:"right"}}
          onClick={handleSignIn}
          >
          Phone SignIn
        </Button>
      </Stack>
      }
    </div>

  );
};

export default PhoneAuth;
